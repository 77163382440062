import React, {useContext, useState} from "react"
import {PERMISSIONS} from "../../../../constants/permissions"
import Popup from "reactjs-popup"
import UpdateTaxesPopup from "../Popups/UpdateTaxesPopup"
import PenIcon from "@mui/icons-material/Create"
import {useSelector} from "react-redux"
import {ThemeContext} from "../../../../styles/theme-context"
import {useTranslation} from "react-i18next"
import {EmptyCard} from "../../../../toolkits/EmptyResult/EmptyCard"

const TaxesCard = ({user, isMyProfile, setUserMessage, receiveUserInfo}) => {
    const { t } = useTranslation("common")

    const [showUpdatePopup, setShowUpdatePopup] = useState(false)
    const {hasPermission} = useSelector((state) => state.profile)
    const theme = useContext(ThemeContext)

    const canEdit = hasPermission(PERMISSIONS.WRITE_SALARY)

    return (
        <>
            <div
                className={`
                    profile-right-taxes 
                    ${canEdit ? "clickable" : ""} 
                    ${canEdit ? theme.profileCardHover : ""} 
                `}
                onClick={() => canEdit && setShowUpdatePopup(true)}
            >
                <span className="t-h3 profile-font-leading">{t("Taxes")}</span>

                {user.taxes.length > 0 ? (
                    <div className="t-h3 profile-right-taxes-content">
                        {user.taxes.map((tax) => {
                            const name = tax.name.toUpperCase()
                            let extra = ""
                            if (tax.percent) {
                                extra = `${Math.round(tax.percent * 1000) / 10}%`
                            }  else if (tax.fixed_amount) {
                                extra = `${tax.fixed_amount} ${tax.fixed_amount_currency.iso_code}`
                            }
                            return `${name} (${extra})`

                        }).join(", ")}
                    </div>
                ) : (
                    <EmptyCard>{t("No taxes")}</EmptyCard>
                )}
                {canEdit  &&
                    <div className="profile-info-card-links">
                        <PenIcon onClick={() => setShowUpdatePopup(true)} className="profile-info-card-pen-icon clickable"/>
                    </div>
                }
            </div>

            <Popup open={showUpdatePopup} closeOnDocumentClick={false} onClose={() => setShowUpdatePopup(false)} modal>
                {close =>
                    <UpdateTaxesPopup
                        user={user}
                        receiveUserInfo={() => receiveUserInfo(user.id, isMyProfile, true)}
                        setUserMessage={setUserMessage}
                        close={close}
                    />
                }
            </Popup>
        </>
    )
}

export default TaxesCard