import React, {useState} from "react"

import CustomPopup from "../../../../toolkits/CustomPopup/CustomPopup"
import SmallButton from "../../../../toolkits/SmallButton/SmallButton"
import DynamicBoxList from "../../../../toolkits/DynamicBoxList"

import {ERROR_CODE, SUCCESS_CODE} from "../../../../constants/other"
import {useUpdateUserTaxesMutation} from "../../../../api/users"
import {useGetTaxesQuery} from "../../../../api/salary"
import {useTranslation} from "react-i18next"


const UpdateTaxesPopup = ({user, setUserMessage, receiveUserInfo, close}) => {
    const { t } = useTranslation("common")
    const [updateUserTaxes, { isLoading: updateLoading }] = useUpdateUserTaxesMutation()
    const [taxesIds, setTaxesIds] = useState(user?.taxes.map((x) => x.id) || [])

    const { data, isLoading: loading } = useGetTaxesQuery()
    const taxes = data || []

    const handleSubmit = () => {
        const data = {
            taxes_ids: taxesIds,
        }
        updateUserTaxes({userId: user.id, data})
            .unwrap()
            .then(() => {
                receiveUserInfo()
                close()
                setUserMessage({message: t("User Taxes were successfully updated"), code: SUCCESS_CODE})
            })
            .catch(error => {
                setUserMessage({message: error.message, code: ERROR_CODE})
            })
    }

    return (
        <CustomPopup text_title={t("Edit Taxes")} className="taxes">
            <div className="popup-content">
                <DynamicBoxList
                    defaultValues={user?.taxes.map((x) => x.id) || []}
                    items={taxes}
                    loading={loading}
                    onChange={setTaxesIds}
                    getContent={tax => {
                        const name = tax.name
                        let extra = ""
                        if (tax.percent) {
                            extra = `${Math.round(tax.percent * 1000) / 10}%`
                        }  else if (tax.fixed_amount) {
                            extra = `${tax.fixed_amount} ${tax.fixed_amount_currency.iso_code}`
                        }
                        return `${name} (${extra})`
                    }}
                />
            </div>
            <div className="popup-bottom">
                <div className="popup-bottom-button-container">
                    <SmallButton btnType="secondary" onClick={close} disabled={updateLoading}> {t("Cancel")} </SmallButton>
                    <SmallButton type="submit" onClick={handleSubmit} loading={updateLoading} disabled={!taxesIds.length}>{t("Save")}</SmallButton>
                </div>
            </div>
        </CustomPopup>
    )
}

export default UpdateTaxesPopup
